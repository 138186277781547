import TemplateA from "../template_a";

export default function CuscoPage(){

    const content = {
        "document": {
          name:"Programa Cusco",
          pdf:"/pdf/destinos/programa-cusco.pdf"},
        "banner":"/images/destinos/cusco/cusco.jpg",
        "title": "Cusco: La Capital del Imperio Inca y Puerta a Machu Picchu",
        "subtitle": "Cusco, conocida como la 'Capital Histórica del Perú', es un destino fascinante que fusiona la majestuosidad de la cultura incaica con la belleza de los paisajes andinos.\n\n Ubicada a más de 3,400 metros sobre el nivel del mar, Cusco es la puerta de entrada a la mundialmente famosa Machu Picchu y el Valle Sagrado de los Incas, un paraíso para los viajeros en busca de historia, cultura y naturaleza.",
        "sections": [
          {
            "title": "Mejor época para visitar Cusco",
            "content": "La mejor época para viajar a Cusco es entre abril y octubre, durante la temporada seca. Los días soleados y las noches frescas hacen de este periodo el más adecuado para explorar los sitios arqueológicos y disfrutar de actividades al aire libre. \n\n Evita los meses lluviosos de diciembre a marzo, especialmente si planeas recorrer lugares como Machu Picchu o la Montaña de los Siete Colores."
          },
          {
            "title": "Qué hacer en Cusco",
            "subsections": [
              {
                "title": "Explora la historia y la arquitectura colonial",
                "image":"/images/destinos/cusco/catedral.jpeg",

                "list": [
                  {
                    "name": "Plaza de Armas",
                    "description": "El corazón de Cusco y su arquitectura colonial.",
                    "image":""
                  },
                  {
                    "name": "Qorikancha",
                    "description": "El Templo del Sol, un símbolo de la grandeza incaica.",
                    "image":""
                  },
                  {
                    "name": "Barrio de San Blas",
                    "description": "Un pintoresco barrio conocido por su arte y su historia.",
                    "image":""

                  }
                ]
              },
              {
                "title": "Visita los sitios arqueológicos cercanos",
                "image":"/images/destinos/cusco/sacsayhuaman.jpeg",
                "list": [
                  {
                    "name": "Sacsayhuamán",
                    "description":"Fortaleza de piedra gigante.",
                    "image":""
                  },
                  {
                    "name": "Qenqo, Puca Pucara, y Tambomachay",
                    "description": "Sitios ceremoniales y arqueológicos.",
                    "image":""
                  }
                ]
              },
              {
                "image":"/images/destinos/cusco/ollantaytambo.jpeg",
                "title": "Recorre el Valle Sagrado de los Incas",
                "list": [
                  {
                    "name": "Pisac",
                    "description": "Con su famoso mercado artesanal y ruinas incas.",
                    
                  },
                  {
                    "name": "Ollantaytambo",
                    "description": "Un complejo inca bien conservado.",
                  },
                  {
                    "name": "Maras y Moray",
                    "description": "Impresionantes terrazas agrícolas.",
                    
                  }
                ]
              }
            ]
          },
          {
            "title": "Gastronomía típica de Cusco",
            "content": "Disfruta de los sabores tradicionales de Cusco con platos típicos como:",
            "list": [
              {
                "name": "Cuy al horno",
                "description": "Un manjar ancestral."
              },
              {
                "name": "Chiriuchu",
                "description": "Un plato tradicional de fiesta."
              },
              {
                "name": "Lawa de maíz",
                "description": "Sopa espesa típica de la región."
              },              
              {
                "name": "Chocolate cusqueño",
                "description": "Endulza tu viaje con este delicioso producto local."
              }
            ]
          },
          
          {
            "title": "Consejos para tu viaje a Cusco",
            "list": [
              {
                "name": "Aclimatación",
                "description": "Cusco está a gran altitud, por lo que es importante descansar y beber té de coca para evitar el mal de altura."
              },
              {
                "name": "Ropa abrigadora",
                "description": "Aunque los días pueden ser cálidos, las noches suelen ser frías."
              },
              {
                "name": "Protector solar",
                "description": "El sol puede ser intenso en las alturas, así que no olvides usar protector solar y mantenerse hidratado."
              },
              {
                "name": "Reserva con antelación",
                "description": "Especialmente para entradas a Machu Picchu, trenes y tours en temporada alta."
              }
            ]
          },
          {
            "title": "Descubre Cusco",
            "content": "Vive la historia y la cultura del antiguo Imperio Inca, recorre Machu Picchu, y sumérgete en las maravillas naturales del Valle Sagrado. \n\n Cusco te ofrece una experiencia única que conectará tu alma con el pasado glorioso de los incas. ¡Planifica tu aventura ahora y vive la magia de Cusco, la capital histórica del Perú!"
          },
          
        ],
        
        "itinerary": [
          {
            "day": 1,
            "title": "Llegada a Cusco",
            "description": [
              "Recepción en el aeropuerto Velasco Astete.",
              "Día libre para aclimatación, compras o paseos."
            ]
          },
          {
            "day": 2,
            "title": "Valle Sagrado",
            "description": [
              "Tour guiado: Pisac y su mercado, Chinchero (textiles), Moray (laboratorio agrícola), Salineras de Maras y Ollantaytambo, con almuerzo buffet Novoandino.",
              "Noche en Cusco."
            ]
          },
          {
            "day": 3,
            "title": "Machu Picchu",
            "description": [
              "Viaje en tren a Aguas Calientes y bus a la ciudadela de Machu Picchu.",
              "Tour guiado (2:30 h) por su historia y arquitectura.",
              "Almuerzo en Aguas Calientes y regreso a Cusco."
            ]
          },
          {
            "day": 4,
            "title": "Montaña de 7 Colores",
            "description": [
              "Ascenso desde Fulawasi (1.5 h) a Vinicunca, a 5,200 m.s.n.m.",
              "Disfruta los paisajes andinos y aprende sobre su historia.",
              "Retorno al hotel en Cusco."
            ]
          },
          {
            "day": 5,
            "title": "Sitios Arqueológicos y Salida",
            "description": [
              "Visita guiada: Saqsaywaman, Qenqo, Tambomachay, Puca Pucara, Catedral y Templo del Sol.",
              "Traslado al aeropuerto."
            ]
          }
        ]
        
        
      }
      

    return (
        <TemplateA data={content} place={"Cusco"} />
    )

}

// {
//   "title": "Machu Picchu: La Joya del Turismo Peruano",
//   "content": "La ciudadela inca de Machu Picchu es una de las 7 maravillas del mundo moderno y el atractivo turístico más visitado de Cusco. Asegúrate de hacer tu reserva con anticipación y considera contratar un guía turístico para conocer la historia detrás de cada piedra."
// },
// {
//   "title": "Vive experiencias únicas en Cusco",
//   "list": [
//     {
//       "name": "Montaña de los Siete Colores",
//       "description": "Paisajes naturales que te dejarán sin aliento."
//     },
//     {
//       "name": "Lago Humantay",
//       "description": "Un lago glaciar en el corazón de los Andes."
//     }
//   ]
// },
