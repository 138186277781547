import {useState, useRef} from "react";

export default function Schedule(props){
    const itinerary = props.itinerary;
    const [isOpen, setIsOpen] = useState(false);

    const contentRef = useRef(null);



    return (

        <div id="schedule">
            <div
                onClick={()=>setIsOpen(!isOpen)}

                className="cursor-pointer flex justify-between items-center px-6 py-3 border-2 border-black rounded-3xl">
                <h2 className="font-bold uppercase text-xl">Itinerario</h2>
                <span className={`transform transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}>
▼
                </span>
            </div>
                        
        {isOpen && (
            <div
                ref={contentRef}
                className="transition-max-height duration-500 ease-in-out overflow-hidden"
                style={{
                    maxHeight: isOpen ? `${contentRef.current?.scrollHeight}px` : '0px',
                }}    
            >
                <div className="p-4  border-2 rounded-3xl mt-4 border-black">
            
                    {itinerary.map((it,idx) => 
                        <article className="my-4" key={"article-"+idx}>
                            <div className="px-6">
                                <h3 className="text-xl font-semibold text-dgtitle">Día {it.day}: {it.title}</h3>
                                <ul className="list-disc">
                                    {it.description.map((desc, jdx) =><li key={"desc-"+jdx}>{desc}</li>)}
                                </ul>

                            </div>
                        </article>
                        )
                    }

                    
                    
                </div>
            </div>
                
        )}
                        

    </div>
        
    )
}