import { REACT_APP_APP_NAME } from "./utils";

export default function InfoItem(props){    
    const {list, title, image} = props.data;
    
    const app_name = REACT_APP_APP_NAME;

    return (
        <div className="grid  grid-cols-1 md:grid-cols-6 lg:gap-x-4 xl:gap-x-10   lg:px-8 lg:py-8 xl:px-14 xl:py-8 even:bg-dgitem-even odd:bg-dgitem-odd rounded-[1rem] lg:rounded-[2rem] xl:rounded-[4rem]">
            <div className=" col-span-1 md:col-span-2 flex">
                <img src={image}  
                    className="rounded-t-[1rem] md:rounded-none w-auto md:h-40 md:w-40 lg:h-40 lg:w-40 xl:h-56 xl:w-56 md:my-auto xl:my-4 md:mx-auto lg:mx-4" 
                    alt={app_name +"-"+ (title.split(" ")).join("-") } />
            </div>
            <div className=" col-span-1 md:col-span-4 flex flex-col justify-center md:gap-y-2 xl:gap-y-6 py-6 lg:py-0 min-h-52">
                <h3 className=" mx-auto md:mx-0 text-sm md:text-md lg:text-lg text-center md:text-left  mb-4 md:mb-0 px-3 py-1 rounded-lg border-black  border-2 w-fit  uppercase font-bold">{title}</h3>
                <ul className="px-8 md:px-2 list-disc">
                    {list.map((it,idx) => 
                        <li key={`desc-${idx}`}> 
                            <h6> <span className="font-semibold">{it.name}</span>: {it.description}</h6>
                        </li>
                    )}
                </ul>                
            </div>
        </div>
    )
}