import { REACT_APP_APP_NAME } from "./utils";

export default function InfoItemB(props){
    const {list, title, image} = props.data;
    const idx = props.idx; 
    
    const app_name = REACT_APP_APP_NAME;
    return (
        <div className="grid grid-cols-1 md:grid-cols-6 lg:gap-x-4 xl:gap-x-10 lg:px-8 lg:py-8 xl:px-14 xl:py-8 max-w-full odd:bg-[#EAD9C6] even:bg-[#E4D2C6] rounded-t-[1rem] md:rounded-none">

            <div className={`col-span-1 md:col-span-4 ${idx % 2 === 0 ? "order-2":"order-1"} 
            flex flex-col justify-center md:gap-y-2 xl:gap-y-6 py-6 lg:py-0 min-h-52`}>

                <h3 className={`mx-auto md:ml-8 text-sm md:text-md lg:text-lg text-center md:text-left  mb-4 md:mb-0 px-2 md:px-3 py-1 rounded-lg w-fit uppercase font-bold ${idx%2===0 ? "bg-[#D4B29B]":"bg-[#D8BC9D]"}`}>{title}</h3>
                <ul className={`${idx%2 ===0 ? "pl-8 ":"pl-10"}  list-disc pr-4`}>
                    {list.map((it,idx) => 
                        <li key={`desc-${idx}`}> 

                            <h6> <span className="font-semibold">{it.name}</span>: {it.description}</h6>
                        </li>
                    )}
                </ul>  
            </div>
            <div className={`col-span-1 md:col-span-2 ${idx % 2 === 0 ? "order-1":"order-2"} flex`}>
                <img src={image} className={`${idx%2===0 ?"rounded-t-[1rem] ":"rounded-b-[1rem] "} md:rounded-none w-auto md:h-40 md:w-40 lg:h-48 lg:w-48 xl:h-60 xl:w-60 md:my-auto xl:my-2 md:mx-auto lg:mx-4`} alt={app_name +"-"+ title} />
            </div>
        </div>
    )
}