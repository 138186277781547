import TemplateA from "../template_a";

export default function ArequipaPage(){

    const content = {
        "document":{
            name:"Programa Arequipa",
            pdf: "/pdf/destinos/programa-arequipa.pdf"
        },
        "banner":"/images/destinos/arequipa/arequipa.jpg",
        "title": "Arequipa: La Ciudad Blanca, el Mejor Destino Turístico del Perú",
        "subtitle": "Arequipa, conocida como la Ciudad Blanca del Perú, es un destino imperdible que combina paisajes únicos, arquitectura colonial y tradiciones ancestrales. Situada al pie del majestuoso volcán Misti, Arequipa ofrece una experiencia inolvidable que fusiona historia, cultura y naturaleza. Es un lugar ideal para explorar tanto la rica herencia colonial como las maravillas naturales que la rodean.",
        "sections": [
          {
            "title": "Mejor época para viajar a Arequipa",
            "content": "La mejor época para visitar Arequipa es entre abril y diciembre, con especial énfasis en mayo a noviembre durante la temporada seca. Este periodo garantiza días soleados y noches frescas, perfectos para disfrutar de atracciones como el Cañón del Colca y el Monasterio de Santa Catalina. Evita viajar entre enero y febrero, cuando las lluvias pueden afectar los planes al aire libre."
          },
          {
            "title": "Qué hacer en Arequipa",
            "subsections": [
              {
                "image":"/images/destinos/arequipa/arequipa-plaza.jpeg",
                "title": "Centro Histórico de Arequipa",
                "list": [
                  {
                    "name": "Plaza de Armas",
                    "description": "El corazón de la ciudad, rodeado de imponentes edificaciones coloniales."
                  },
                  {
                    "name": "Monasterio de Santa Catalina",
                    "description": "Un enclave histórico que te transporta a la época colonial."
                  }
                ]
              },
              {
                "image":"/images/destinos/arequipa/arequipa-colca.jpg",
                "title": "Maravillas Naturales",
                "list": [
                  {
                    "name": "Cañón del Colca",
                    "description": "Uno de los cañones más profundos del mundo, hogar del majestuoso cóndor."
                  },
                  {
                    "name": "Baños termales de La Calera",
                    "description": "Relájate en aguas termales rodeadas de impresionantes paisajes."
                  },
                  {
                    "name": "Reserva Nacional de Salinas y Aguada Blanca",
                    "description": "Un refugio natural ideal para el avistamiento de fauna andina y paisajes deslumbrantes."
                  }
                ]
              },
              {
                "image":"/images/destinos/arequipa/ciudad-arequipa.jpeg",
                "title": "Cultura y los miradores",
                "list": [
                  {
                    "name": "Miradores de Yanahuara y Carmen Alto",
                    "description": "Ofrecen vistas panorámicas espectaculares de la ciudad y el volcán Misti."
                  },
                  {
                    "name": "Picanterías y mercados locales",
                    "description": "Sumérgete en la gastronomía arequipeña visitando sus tradicionales picanterías y mercados."
                  }
                ]
              }
            ]
          },
          {
            "title": "Gastronomía típica de Arequipa",
            "content": "Arequipa es considerada la capital gastronómica del Perú, famosa por su comida tradicional.",
            "list": [
              {
                "name": "Rocoto relleno",
                "description": "Un platillo picante hecho con rocoto, carne y queso.",
                "image":"/images/destinos/arequipa/arequipa-rocoto-relleno.jpeg"
              },
              {
                "name": "Adobo arequipeño",
                "description": "Un guiso de cerdo marinado con especias y chicha de jora.",
                "image":"/images/destinos/arequipa/arequipa-adobo.jpg"
              },
              {
                "name": "Chupe de camarones",
                "description": "Sopa espesa con camarones frescos y verduras.",
                "image":"/images/destinos/arequipa/arequipa-chupe-camarones.jpg"
              },
              {
                "name": "Queso helado",
                "description": "Un dulce tradicional que debes probar como postre.",
                "image":"/images/destinos/arequipa/arequipa-queso-helado.png"
              }
            ]
          },
          {
            "title": "Consejos para visitar Arequipa",
            "list": [
              {
                "name": "Ropa adecuada",
                "description": "Lleva ropa ligera para el día y abrigadora para la noche, ya que las temperaturas varían."
              },
              {
                "name": "Protección solar e hidratación",
                "description": "Usa protector solar y mantente hidratado, especialmente en lugares altos como el Cañón del Colca."
              },
              {
                "name": "Reservas anticipadas",
                "description": "Si planeas realizar excursiones al Cañón del Colca o visitar el Monasterio de Santa Catalina, asegúrate de reservar con anticipación, sobre todo en temporada alta (julio y agosto)."
              },
              {
                "name": "Hospedaje",
                "description": "Durante la temporada alta, reserva tu alojamiento con tiempo para evitar inconvenientes."
              }
            ]
          },
          
          
          {
            "title": "¿Por qué visitar Arequipa?",
            "content": "Arequipa es uno de los destinos turísticos más solicitados del Perú gracias a su mezcla única de historia, cultura, impresionantes paisajes naturales y su famosa gastronomía. \n\nCon lugares emblemáticos como el Cañón del Colca, el Monasterio de Santa Catalina y una cocina tradicional que ha alcanzado fama internacional, Arequipa es una ciudad que promete una experiencia inolvidable. ¡Planea tu viaje ahora y descubre por qué Arequipa es uno de los mejores destinos turísticos en Sudamérica y el Perú!"
          },
          
          
        ],
        
            "itinerary": [
              {
                "day": 1,
                "title": "Arequipa y Monasterio de Santa Catalina",
                "description": [
                  "Traslado al hotel.",
                  "City tour: Plaza de Armas, Catedral, Monasterio de Santa Catalina, Iglesia de la Compañía y San Lázaro."
                ]
              },
              {
                "day": 2,
                "title": "Volcanes y Valle del Colca",
                "description": [
                  "Recorre la Reserva Salinas y Aguada Blanca.",
                  "Vistas del Sabancaya desde Patapampa (4,900 m).",
                  "Almuerzo en Achoma, actividades locales y baños termales."
                ]
              },
              {
                "day": 3,
                "title": "Cruz del Cóndor y Cañón del Colca",
                "description": [
                  "Disfruta el vuelo del cóndor y visita Maca y Yanque.",
                  "Regreso a Arequipa."
                ]
              },
              {
                "day": 4,
                "title": "Salar de Salinas y Baños Termales",
                "description": [
                  "Visita Chiguata, el salar de Salinas y relájate en los baños de Lojen.",
                  "Traslado al aeropuerto."
                ]
              }
            ]
          
      }
      

    return (
        <TemplateA data={content} place={"Arequipa"} />
    )
}