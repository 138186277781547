import {
	ESNNA,
	FACE_LINK,
	INST_LINK,
	MAIL_LINK,
	MINCETUR,
	PROTECTION,
	QUALITY,
	TADVISOR_LINK,
	TERMS,
	TK_LINK,
	WS_LINK,
	YT_LINK,
} from "./utils";

import { button_text, footer_text } from "../translation";
import p from "../../package.json";
import { useContext, useRef, useState } from "react";
import { HeaderContext } from "./header";
import saveSub from "../providers/subscription";
import { AppContext } from "../App";
import ConfirmSuscriptionDialog from "./confirm_suscription_dialog";

export default function Footer() {
	const { lang } = useContext(HeaderContext);
	const { handleLoading } = useContext(AppContext);
	const userRef = useRef();
	const emailRef = useRef();
	const [open, setOpen] = useState(false);

	const sendEmail = (e) => {
		e.preventDefault();
		const body = {
			name: userRef.current.value,
			email: emailRef.current.value,
		};
		handleLoading(true);
		

		saveSub(body)
			.then((res) => {
				if (res.code === 409) {					
					alert("El correo electrónico ingresado ya esta registrado");
				} else {
					setOpen(true);
					
				}
			})
			.catch((e) => {
				console.log("error", e);
				alert(`Error ${e}`);
			})
			.finally(() => handleLoading(false));
	};

	return (
		<footer className=" bg-dggray-200 font-poppins">
			<ConfirmSuscriptionDialog open={open} onClose={()=>setOpen(false)} />
			<div className=" px-6 md:px-0 w-full ">									
				<div
					id="icons"
					className="flex flex-row gap-6 justify-center  items-center py-8 flex-wrap "
				>
					<a target="_blank" rel="noreferrer" href={YT_LINK}>
							<img
								alt="promo-image"
								className="h-6 md:h-8 lg:h-10"
								src="/images/yt-footer.png"
							/>
					</a>
					<a target="_blank" rel="noreferrer" href={FACE_LINK}>
						<img
							alt="promo-image"
							className="h-6 md:h-8 lg:h-10"
							src="/images/face-footer.png"
						/>
					</a>
					<a target="_blank" rel="noreferrer" href={INST_LINK}>
						<img
							alt="promo-image"
							className="h-6 md:h-8 lg:h-10"
							src="/images/inst-footer.png"
						/>
					</a>
					<a target="_blank" rel="noreferrer" href={TK_LINK}>
						<img
							alt="promo-image"
							className="h-6 md:h-8 lg:h-10"
							src="/images/tk-footer.png"
						/>
					</a>
					<a target="_blank" rel="noreferrer" href={WS_LINK}>
						<img
							alt="promo-image"
							className="h-6 md:h-8 lg:h-10"
							src="/images/ws-footer.png"
						/>
					</a>
					<a target="_blank" rel="noreferrer" href={TADVISOR_LINK}>
						<img
							alt="promo-image"
							className=" h-6 md:h-8 lg:h-10 my-auto"
							src="/images/tripadvisor.png"
						/>
					</a>
					<a
						target="_blank"
						rel="noreferrer"
						href={`mailto:${MAIL_LINK}`}
					>
						<img
							alt="promo-image"
							className="h-6 md:h-8 lg:h-10"
							src="/images/mail.png"
						/>
					</a>
				</div>
				<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 py-4 px-0 md:px-10 gap-y-4">
					<div className="col-span-2">
							<h2 className="text-lg lg:text-xl font-semibold  mb-2 font-ivymode text-dgtitle">Destinos Nacionales</h2>
							<div className="grid grid-cols-2 sm:grid-cols-2 gap-y-2 gap-x-4 text-md md:text-lg lg:text-lg">
								<a href="/destinos/cusco" className="hover:underline">Viajes a Cusco</a>
								<a href="#" className="hover:underline">Viajes a Máncora</a>
								<a href="/destinos/cajamarca" className="hover:underline">Viajes a Cajamarca</a>
								<a href="#" className="hover:underline">Viajes a Huaraz</a>
								<a href="/destinos/arequipa" className="hover:underline">Viajes a Arequipa</a>
								<a href="#" className="hover:underline">Viajes a Trujillo</a>
								<a href="#" className="hover:underline">Viajes a la Selva Peruana</a>
								<a href="#" className="hover:underline">Viajes a Tarapoto</a>
								<a href="#" className="hover:underline">Viajes a las Playas del norte</a>
								<a href="#" className="hover:underline">Viajes a Ica</a>
								<a href="#" className="hover:underline">Viajes a Chachapoyas</a>
								<a href="#" className="hover:underline">Viajes a Puno</a>
						</div>
					</div>
					
					
					<div className="col-span-2">
						<h2 className="text-lg lg:text-xl font-semibold  mb-2 font-ivymode text-dgtitle">Destinos Internacionales</h2>
						<div className="grid grid-cols-2 sm:grid-cols-2 gap-y-2 gap-x-4 text-md md:text-lg lg:text-lg">
							<a href="#" className="hover:underline">Viajes a Europa</a>
							<a href="#" className="hover:underline">Viajes a Turquía</a>
							<a href="/destinos/cartagena" className="hover:underline">Viajes a Cartagena</a>
							<a href="/destinos/tailandia" className="hover:underline">Viajes a Tailandia</a>
							<a href="#" className="hover:underline">Viajes a Cancún</a>
							<a href="#" className="hover:underline">Viajes a Francia</a>
							<a href="/destinos/mexico" className="hover:underline">Viajes a México</a>
							<a href="#" className="hover:underline">Viajes al Caribe</a>
							<a href="#" className="hover:underline">Viajes a Ecuador</a>
							<a href="#" className="hover:underline">Viajes a España</a>
							<a href="#" className="hover:underline">Viajes a Grecia</a>
							<a href="#" className="hover:underline">Viajes a Italia</a>
						</div>
					</div>	
					<div className="flex flex-col gap-y-2 text-md md:text-lg lg:text-xl">
						<p className=" text-dgtitle font-ivymode font-bold text-lg lg:text-xl">
							{footer_text.policy[lang]}
						</p>
						
						<a
							href={PROTECTION}
							className=" hover:underline font-poppins"
							target="_blank"
							rel="noreferrer"
						>
							{footer_text.policy.protection[lang]}
						</a>
						<a
							href={QUALITY}
							className=" hover:underline font-poppins"
							target="_blank"
							rel="noreferrer"
						>
							{footer_text.policy.quality[lang]}
						</a>
					</div>

				</div>
				
					
			
				

				<div className="px-0 md:px-10 mt-10 md:mt-0 grid grid-cols-2 lg:grid-cols-5 gap-4 ">
					
					<div className="col-span-2 " id="sevices">
						<h2 className="text-lg lg:text-xl font-semibold text-dgtitle font-ivymode mb-2">Servicios</h2>
						<div className="grid grid-cols-1 sm:grid-cols-2 gap-y-2 gap-x-10 text-md md:text-lg lg:text-lg">
							<a href="#" className="hover:underline">Paquete viajes todo incluido</a>
							<a href="#" className="hover:underline">Vacaciones familiares</a>
							<a href="#" className="hover:underline">Tours guiados por Cusco</a>
							<a href="#" className="hover:underline">Tours guiados por Machu Picchu</a>
							<a href="#" className="hover:underline">Ofertas de última hora</a>
							<a href="#" className="hover:underline">Viajes de aventura en Perú</a>
							<a href="#" className="hover:underline">Experiencias únicas</a>
							<a href="#" className="hover:underline">Canal de difusión</a>
						</div>
						<img alt="promo-image" src="/images/logo.png" className="hidden lg:block mt-10 h-20 lg:h-14 xl:h-16 " />

					</div>
					<div className="flex flex-col gap-y-2 col-span-2 lg:col-span-1 ">
						<h2 className="text-lg lg:text-xl text-dgtitle font-ivymode font-bold mb-2">
							{footer_text.certis[lang]}
						</h2>
						<a
							href={ESNNA}
							target="_blank"
							rel="noreferrer"
							className="text-lg hover:underline font-poppins"
						>
							{footer_text.certis.esnna[lang]}
						</a>
						<a
							href={TERMS}
							target="_blank"
							rel="noreferrer"
							className="text-lg hover:underline font-poppins"
						>
							{footer_text.certis.terms[lang]}
						</a>
						<a
							href={MINCETUR}
							target="_blank"
							rel="noreferrer"
							className="text-lg hover:underline font-poppins"
						>
							{footer_text.certis.mincetur[lang]}
						</a>
						<img alt="promo-image" src="/images/logo.png" className="block lg:hidden mt-10 h-16 lg:h-14 xl:h-16 mx-auto" />
					</div>
					
					
					<div className="col-span-2 bg-dgblue py-8 px-6 rounded-t-3xl w-full max-w-md mx-auto shadow-md ">
						
						<h2
								dangerouslySetInnerHTML={{
									__html: footer_text.newsletter[lang],
								}}
								className="text-2xl text-center font-ivymode font-semibold text-white"
							/>
						<form onSubmit={sendEmail} className="w-full ">
							<div className="relative w-full  space-y-4 flex flex-col mt-5 ">
								<input
									required
									ref={userRef}
									type="text"
									placeholder={button_text.name[lang]}
									className="w-full px-4 py-2 rounded-full bg-white text-gray-600 border border-gray-300 focus:ring-2 focus:ring-gray-400 outline-none"
								/>
								<input
									required
									ref={emailRef}
									type="email"
									placeholder={button_text.email[lang]}
									className="w-full px-4 py-2 rounded-full bg-white text-gray-600 border border-gray-300 focus:ring-2 focus:ring-gray-400 outline-none "
								/>
								<button
									type="submit"
									className="w-32 py-2 mx-auto  hover:shadow-lg shadow-md border-0 rounded-full bg-white"
								>
									Enviar
								</button>
							</div>
						</form>
					</div>
				</div>
				
			</div>
			<div className="relative text-center  py-1 mr-4 font-poppins">
				<a href={"http://www.devosinc.com"} target="_blank" rel="noreferrer">{footer_text.dev[lang]}</a>
				<span className="absolute z-10 right-4 md:right-10">{p.version}</span>
			</div>
		</footer>
	);
}
