import TemplateB from "../template_b"

export default function TailandiaPage(){
    const content = {
        "document":{
            name:"Programa Tailandia",
            pdf:"/pdf/destinos/programa-tailandia.pdf"
        },
        "banner":"/images/destinos/tailandia/conoce-tailandia.jpeg",
        "title": "Tailandia: El País de las Sonrisas y los Paisajes Exóticos",
        "subtitle": "Tailandia combina playas paradisíacas, templos antiguos, junglas exuberantes y una cultura vibrante. Conocido como el 'País de las Sonrisas', es un destino que fascina por su mezcla de tradición, modernidad y hospitalidad.",
        "sections": [
            {
            "title": "Mejor época para viajar a Tailandia",
            "content": "Tailandia tiene tres estaciones principales para planificar el viaje:\n\n- Temporada seca (noviembre a febrero): Ideal para explorar cualquier región con temperaturas agradables y cielos despejados.\n\n- Temporada de lluvias (junio a octubre): Menos turistas y precios más bajos, pero con presencia de monzones.\n\n- Temporada cálida (marzo a mayo): Perfecta para disfrutar de las playas, aunque las temperaturas son más altas."
            },
            {
            "title": "Destinos turísticos imperdibles en Tailandia",
            "subsections": [
                {
                "title": "Bangkok: La Capital Vibrante",
                "image": "/images/destinos/tailandia/bangkok.jpeg",
                "list": [
                    {
                    "name": "Gran Palacio y Templo del Buda Esmeralda (Wat Phra Kaew)",
                    "description": "Uno de los complejos más emblemáticos de la ciudad."
                    },
                    {
                    "name": "Wat Arun (Templo del Amanecer)",
                    "description": "Un espectacular templo ubicado a orillas del río Chao Phraya."
                    },
                    {
                    "name": "Mercados flotantes y Khao San Road",
                    "description": "Explora los mercados únicos y la vida nocturna vibrante de Bangkok."
                    }
                ]
            },
            {
            "title": "Chiang Mai: Tradición y Naturaleza en el Norte",
            "image": "/images/destinos/tailandia/chiangmai.webp",
            "list": [
                {
                "name": "Templos históricos: Wat Phra Singh y Wat Chedi Luang",
                "description": "Admira la arquitectura y la historia de estos templos icónicos."
                },
                {
                "name": "Festival de las Linternas",
                "description": "Un evento mágico lleno de cultura y tradición."
                },
                {
                "name": "Doi Inthanon y mercados nocturnos",
                "description": "Explora la montaña más alta de Tailandia y disfruta del bullicio de los mercados nocturnos."
                }
            ]
            },
            {
            "title": "Playas y islas paradisíacas del sur",
            "image": "/images/destinos/tailandia/phuket.jpg",
            "list": [
                {
                "name": "Phuket",
                "description": "Disfruta de playas espectaculares y una vibrante vida nocturna."
                },
                {
                "name": "Koh Phi Phi",
                "description": "Famosa por sus aguas cristalinas y paisajes impresionantes."
                },
                {
                "name": "Krabi, Koh Samui y Koh Phangan",
                "description": "Relájate en estas islas paradisíacas perfectas para cualquier tipo de viajero."
                }
            ]
            },
            {
            "title": "Ayutthaya y Sukhothai: Patrimonio Histórico",
            "image": "/images/destinos/tailandia/ayutthaya.jpg",
            "list": [
                {
                "name": "Ruinas de Ayutthaya",
                "description": "Un sitio histórico lleno de encanto y arquitectura antigua."
                },
                {
                "name": "Parque Histórico de Sukhothai",
                "description": "Descubre las raíces de la civilización tailandesa."
                }
            ]
            },
            {
            "title": "Kanchanaburi: Historia y Naturaleza",
            "image": "/images/destinos/tailandia/kanchanaburi.jpg",
            "list": [
                {
                "name": "Puente sobre el río Kwai",
                "description": "Un sitio histórico que cuenta una historia conmovedora de la Segunda Guerra Mundial."
                },
                {
                "name": "Parque Nacional Erawan",
                "description": "Famoso por sus cascadas y su naturaleza exuberante."
                }
            ]
            }
        ]
        },
            
            {
                "title": "Gastronomía tailandesa: Una explosión de sabores",
                "content": "Disfruta de los sabores auténticos de Tailandia a través de platos icónicos como:",
                "list": [
                  {
                    "name": "Pad Thai",
                    "description": "Un salteado de fideos de arroz con una mezcla de sabores dulces, ácidos y salados."
                  },
                  {
                    "name": "Tom Yum",
                    "description": "Una sopa picante y ácida con hierbas frescas, camarones y especias."
                  },                  
                  {
                    "name": "Mango Sticky Rice",
                    "description": "Un postre dulce que combina arroz glutinoso con mango fresco y leche de coco."
                  },
                  {
                    "name": "Som Tam",
                    "description": "Una ensalada de papaya verde que equilibra los sabores dulces, salados, ácidos y picantes."
                  }
                ]
              },
            {
                "title": "Consejos para visitar Tailandia",
                "list": [
                    {
                    "name": "Vestimenta",
                    "description": "Lleva ropa ligera, pero cubre hombros y rodillas al visitar templos."
                    },
                    {
                    "name": "Transporte",
                    "description": "Usa tuk-tuks, taxis con taxímetro o la aplicación Grab para moverte por el país."
                    },
                    {
                    "name": "Regateo",
                    "description": "Es común en mercados, pero hazlo de manera respetuosa."
                    },
                    {
                    "name": "Cultura",
                    "description": "Evita tocar la cabeza de las personas y señalar con los pies."
                    },
                    {
                    "name": "Salud",
                    "description": "Bebe agua embotellada y considera vacunarte contra la fiebre amarilla."
                    }
                ]
            },
            {
            "title": "Descubre Tailandia",
            
            "content": "Tailandia es un destino que combina historia milenaria, paisajes exóticos, templos majestuosos y una gastronomía deliciosa. Desde relajarte en sus playas hasta explorar selvas tropicales y templos sagrados, Tailandia tiene algo para todos. ¡Vive la magia de este país y descubre el corazón de Asia!"
            },
            {
                "title": "The Grand Palace",
                "image":"/images/destinos/tailandia/the-grand-palace.png",
                "content": "The Grand Palace en Bangkok es una joya arquitectónica que resplandece con su opulencia y detalles dorados. Es el corazón histórico de Tailandia, donde el majestuoso templo del Buda Esmeralda 🧘‍♂️ y otros tesoros culturales esperan ser descubiertos. ¡Una parada obligada en tu aventura tailandesa!"
            },
        ],


            "itinerary": [
            {
                "day": 1,
                "title": "Bangkok",
                "description": [
                "Llegada a Bangkok, recepción y traslado al hotel.",
                "Tarde libre para explorar la ciudad.",
                "Alojamiento."
                ]
            },
            {
                "day": 2,
                "title": "Bangkok",
                "description": [
                "Visita a Wat Traimit, Wat Pho, Gran Palacio Real y el Buda Esmeralda.",
                "Tarde libre.",
                "Alojamiento."
                ]
            },
            {
                "day": 3,
                "title": "Bangkok",
                "description": [
                "Día libre para explorar la ciudad.",
                "Opción de visitar el Mercado Flotante.",
                "Alojamiento."
                ]
            },
            {
                "day": 4,
                "title": "Bangkok - Chiang Mai",
                "description": [
                "Traslado al aeropuerto para vuelo (no incluido) a Chiang Mai.",
                "Llegada y traslado al hotel.",
                "Tarde libre.",
                "Alojamiento."
                ]
            },
            {
                "day": 5,
                "title": "Chiang Mai",
                "description": [
                "Visita al Santuario de Elefantes, interacción y baño con ellos.",
                "Almuerzo y regreso al hotel.",
                "Alojamiento."
                ]
            },
            {
                "day": 6,
                "title": "Chiang Mai",
                "description": [
                "Visita a Wat Doi Suthep, Talat Warorod, Wat Chedi Luang y Wat Phra Singh.",
                "Alojamiento."
                ]
            },
            {
                "day": 7,
                "title": "Chiang Mai - Phuket",
                "description": [
                "Tiempo libre hasta traslado al aeropuerto para vuelo (no incluido) a Phuket.",
                "Llegada y traslado al hotel.",
                "Tarde libre.",
                "Alojamiento."
                ]
            },
            {
                "day": 8,
                "title": "Phuket",
                "description": [
                "Día libre para disfrutar de las playas o realizar excursiones opcionales, como Phi Phi en speedboat.",
                "Alojamiento."
                ]
            },
            {
                "day": 9,
                "title": "Phuket",
                "description": [
                "Tiempo libre para actividades opcionales como buceo, snorkeling o visita a la bahía de Phang Nga.",
                "Alojamiento."
                ]
            },
            {
                "day": 10,
                "title": "Phuket",
                "description": [
                "Día libre hasta el traslado al aeropuerto.",
                "Fin de los servicios."
                ]
            }
            ]
    }
      


    
    return (<TemplateB data={content} place={"Tailandia"} />)
}