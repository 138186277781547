import React, { useRef, useState } from "react";
import Cookies from "js-cookie";
import { createContext, useContext } from "react";
import { FACE_LINK, INST_LINK, TK_LINK, WS_LINK } from "./utils";
import { header_text } from "../translation";
import CotiDialog from "./coti_dialog";
import PDFDialog from "./pdf_dialog";
import SorryDialog from "./sorry_dialog";

export const HeaderContext = createContext();

export default function Header() {
	const {
		lang,
		setLang,
		openCoti,
		setOpenCoti,
		item,
		openInfo,
		setOpenInfo,
		openSorry,
		setOpenSorry,
	} = useContext(HeaderContext);

	const handleLang = (value) => {
		Cookies.set("ulang", value);
		setLang(value);
	};

	const [menuOpen, setMenuOpen] = useState(false);

	const menuRef = useRef();

	return (
		<header className="w-full py-6 flex">
			<SorryDialog open={openSorry} onClose={() => setOpenSorry(false)} />
			<PDFDialog
				item={item}
				onClose={() => setOpenInfo(false)}
				open={openInfo}
			/>

			<CotiDialog isOpen={openCoti} onClose={() => setOpenCoti(false)} />
			<div className="hidden w-full md:grid grid-cols-3 my-auto ">
				<div className=" flex flex-row flex-start items-center ">
					<button
						onClick={() => setOpenCoti(true)}
						className="ml-8 text-lg border-2 rounded-full text-dgtitle border-dgtitle hover:bg-dgwhite hover:text-black  px-4 py-1 font-poppins shadow-lg"
					>
						{header_text.coti[lang]}
					</button>
				</div>
				<div className="">
					<a href="/">
						<img alt="promo-image" src="/images/logo.png" className="w-full md:w-[95%] xl:w-full m-auto" />
					</a>
				</div>
				
				<div className="   flex flex-row justify-around ">
					<div className="flex flex-row items-center gap-x-2  ">
						<button
							className={`rounded hover:bg-dggreen hover:text-dgwhite p-2 text-black xl:text-xl ${
								lang === "en"
									? "bg-dgblue text-dgwhite"
									: "bg-transparent"
							}`}
							onClick={() => handleLang("en")}
						>
							EN
						</button>
						<p > / </p>
						<button
							className={`rounded hover:bg-dgreen hover:text-dgwhite p-2  text-black xl:text-xl ${
								lang === "es"
									? "bg-dgblue text-dgwhite"
									: "bg-transparent"
							}`}
							onClick={() => handleLang("es")}
						>
							{" "}
							ES
						</button>
					</div>
					<div className=" flex flex-row  gap-2 md:gap-3 lg:gap-3 xl:gap-4 items-center   justify-center">

						<a target="_blank" rel="noreferrer" href={FACE_LINK}>
							<img
								alt="promo-image-face"
								className="w-auto h-auto  md:h-6 md:w-6 xl:h-8 xl:w-8"
								src="/images/face.png"
							/>
						</a>
						<a target="_blank" rel="noreferrer" href={INST_LINK}>
							<img
								alt="promo-image-instagram"
								className="w-auto h-auto  md:h-6 md:w-6 xl:h-8 xl:w-8"
								src="/images/inst.png"
							/>
						</a>
						<a target="_blank" rel="noreferrer" href={TK_LINK}>
							<img
								alt="promo-image-tiktok"
								className="w-auto h-auto  md:h-6 md:w-6 xl:h-8 xl:w-8"
								src="/images/tiktok.png"
							/>
						</a>
						<a target="_blank" rel="noreferrer" href={WS_LINK}>
							<img
								alt="promo-image-whatsapp"
								className="w-auto h-auto  md:h-6 md:w-6 xl:h-8 xl:w-8"
								src="/images/ws.png"
							/>
						</a>
					</div>
				</div>
				
			</div>

			<div className="flex md:hidden items-center ml-auto">
				<div className="ml-4">
					<a href="/">
						<img
							alt="promo-image"
							src="/images/logo.png"
							className="w-[80%] md:w-full"
						/>
					</a>
				</div>
				<div className="flex flex-row justify-center items-center">
					<button
						className={`rounded hover:bg-dggreen hover:text-dgwhite p-2 text-black text-md xl:text-xl ${
							lang === "en" ? "bg-dgblue text-dgwhite" : "bg-transparent"
						}`}
						onClick={() => handleLang("en")}
					>
						EN
					</button>
					<p className="mx-2 md:mx-4 "> / </p>
					<button
						className={`rounded hover:bg-dgreen hover:text-dgwhite p-2  mr-1 md:mr-10 text-black text-md xl:text-xl ${
							lang === "es" ? "bg-dgblue text-dgwhite" : "bg-transparent"
						}`}
						onClick={() => handleLang("es")}
					>
						{" "}
						ES
					</button>
				</div>
				<button
					onClick={() => setMenuOpen(!menuOpen)}
					className="p-3 mr-1 md:mr-2 focus:outline-none focus:rounded-full focus:shadow-xl active:rounded-full active:shadow-xl "
				>
					<svg
						className="w-6 h-6"
						fill="none"
						stroke="currentColor"
						viewBox="0 0 24 24"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth={2}
							d="M4 6h16M4 12h16M4 18h16"
						/>
					</svg>
				</button>
			</div>

			{menuOpen && (
				<div
					ref={menuRef}
					className="xl:hidden absolute right-0 top-20 bg-white p-4 shadow-lg rounded-b-xl overflow-y-auto max-h-[65vh] z-10"
				>
					<div className="flex flex-col gap-4">
						<a
							className={`px-4 py-2 rounded-2xl hover:underline hover:text-pxgreen hover:border-pxgreen`}
							href="/nosotros"
						>
							Nosotros
						</a>
						<a
							className={`px-4 py-2 rounded-2xl hover:underline hover:text-pxgreen hover:border-pxgreen`}
							href="/vuelos-nacionales"
						>
							Vuelos Nacionales
						</a>
						<a
							className={`px-4 py-2 rounded-2xl hover:underline hover:text-pxgreen hover:border-pxgreen`}
							href="/vuelos-internacionales"
						>
							Vuelos Internacionales
						</a>
						<a
							className={`px-4 py-2 rounded-2xl hover:underline hover:text-pxgreen hover:border-pxgreen`}
							href="/vpromo"
						>
							Viajes de Promoción
						</a>
						<a
							rel="noreferrer"
							className={`px-4 py-2 rounded-2xl hover:underline hover:text-pxgreen hover:border-pxgreen`}
							href={FACE_LINK}
							target="_blank"
						>
							Facebook
						</a>
						<a
							rel="noreferrer"
							className={`px-4 py-2 rounded-2xl hover:underline hover:text-pxgreen hover:border-pxgreen`}
							href={INST_LINK}
							target="_blank"
						>
							Instagram
						</a>
						<a
							rel="noreferrer"
							className={`px-4 py-2 rounded-2xl hover:underline hover:text-pxgreen hover:border-pxgreen`}
							href={TK_LINK}
							target="_blank"
						>
							TikTok
						</a>
						<a
							rel="noreferrer"
							className={`px-4 py-2 rounded-2xl hover:underline hover:text-pxgreen hover:border-pxgreen`}
							href={WS_LINK}
							target="_blank"
						>
							Whatsapp
						</a>
					</div>
				</div>
			)}
		</header>
	);
}
