export default function TipItem(props){
    const {name, description, image} = props.data;
    return (
        <div className="border-2 p-6 lg:p-8 xl:p-9 border-black text-xl text-center bg-dgitem-tip  rounded-[1rem] xl:rounded-[4rem] shadow-lg">
            <div className="min-h-32 lg:min-h-36">
                <h4 className="font-bold min-h-16 flex items-center justify-center">
                    {name}:
                </h4>
                <h5>
                    {description}
                </h5>
            </div>
            {image && <img src={image} alt={name} className="w-60 h-60  m-auto mt-4"/>}
        </div>
    )
}