import { useRef } from 'react';

// Custom hook to manage smooth scrolling to a specific element
export const useScrollTo = () => {
  // Create a reference to attach to the target element
  const elementRef = useRef(null);

  // Function to scroll to the referenced element with smooth behavior
  const scrollToElement = () => {
    if (elementRef.current) {
      // Using 'scrollIntoView' with smooth behavior
      elementRef.current.scrollIntoView({  behavior: 'smooth' });
    }
  };

  // Return the reference and the scroll function
  return { elementRef, scrollToElement };
};


