// src/App.js
import React, { createContext, useState } from "react";

import Home from "./pages/home";
import Aboutus from "./pages/aboutus";
import National from "./pages/national";
import International from "./pages/international";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Providers from "./providers/header";
import VPromo from "./pages/vpromo";
import Wedding from "./pages/wedding";
import TemplateA from "./pages/template_a";
import TemplateB from "./pages/template_b";
import ArequipaPage from "./pages/destinos/arequipa";
// import ChachapoyasPage from "./pages/destinos/chachapoyas";
import CuscoPage from "./pages/destinos/cusco";
import CajamarcaPage from "./pages/destinos/cajamarca";
import TailandiaPage from "./pages/destinos/tailandia";
import MexicoPage from "./pages/destinos/mexico";
import CartagenaPage from "./pages/destinos/cartagena";

export const AppContext = createContext();
function App() {
	const [loading, setLoading] = useState(false);

	const handleLoading = (v) => {
		setLoading(v);
		if (v) {
			document.body.classList.add("overflow-hidden");
		} else {
			document.body.classList.remove("overflow-hidden");
		}
	};

	return (
		<div id="home" className="relative">
			<Providers>
				<AppContext.Provider value={{ loading, handleLoading }}>
					{loading && (
						<div className="fixed inset-0 bg-gray-700 bg-opacity-75 flex items-center justify-center z-50">
							<img
								src="/images/loading.gif"
								className="m-auto h-32"
								alt="plane animation"
							/>
						</div>
					)}
					<Router>
						<Routes>
							<Route exact path="/" element={<Home />} />
							<Route path="/nosotros" element={<Aboutus />} />
							<Route path="/vuelos-nacionales" element={<National />} />
							<Route path="/vuelos-internacionales" element={<International />} />
							<Route path="/viajes-promocion" element={<VPromo />} />
							<Route path="/bodas" element={<Wedding />} />
							<Route path="/template" element={<TemplateA />} />
							<Route path="/templateB" element={<TemplateB />} />
							<Route path="/destinos/cusco" element={<CuscoPage />} />
							<Route path="/destinos/arequipa" element={<ArequipaPage />} />
							<Route path="/destinos/cajamarca" element={<CajamarcaPage />} />
							<Route path="/destinos/tailandia" element={<TailandiaPage />} />
							<Route path="/destinos/mexico" element={<MexicoPage />} />
							<Route path="/destinos/cartagena" element={<CartagenaPage />} />

						</Routes>
					</Router>
				</AppContext.Provider>
			</Providers>
		</div>
	);
}

export default App;
